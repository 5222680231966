// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import { type UsersList, type Authenticator } from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type AxwayAuthenticator = {|
  ...Authenticator,
  client_id: string,
  organization_id: string,
  added_by_external_user_email?: ?string,
|};

export type AxwayAuthenticatorsList = $ReadOnlyArray<AxwayAuthenticator>;

export type AxwayAuthenticatorsListResponse = {|
  axway_authenticators: AxwayAuthenticatorsList,
  users: UsersList,
|};

export async function list(): FetchResponse<AxwayAuthenticatorsListResponse> {
  return fetch(`${api}/${version}/axway_authenticators`).then(parse);
}

export type AxwayAuthenticatorsCreateRequestParams = {
  client_id: string,
  organization_id: string,
  private_key: string,
  added_by_external_user_email?: ?string,
  ...
};

export type AxwayAuthenticatorCreateResponse = AxwayAuthenticator;

export async function create(
  params: AxwayAuthenticatorsCreateRequestParams,
): FetchResponse<AxwayAuthenticatorCreateResponse> {
  return fetch(`${api}/${version}/axway_authenticators`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
